.setLanguage-select {
  padding-right: 8px;
}
.setLanguage-select .MuiOutlinedInput-notchedOutline {
  border: none;
}
.setLanguage-menu {
  padding: 0;
  padding-right: 14px !important;
  display: flex;
  align-items: center;
}
.setLanguage-iconWrapper {
  width: 1.3rem;
  height: 1.3rem;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
  outline: 1px solid #ddd;
}
.setLanguage-size {
  display: block;
  width: 2.6rem;
  height: 100%;
  transform: translateX(-25%);
}
.setLanguage-label {
  padding: 0 8px;
  line-height: initial;
}
