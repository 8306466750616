.content {
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-rows: 85% 1fr;
  gap: 15px;
}

.block {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}

.static {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 100%;
  overflow: auto;
}

.actionContent {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.controlContent {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
}

.buttonsContainer {
  display: flex;
  width: 100%;
}

.actionButtons {
  padding: 10px 50px;
  white-space: nowrap;
  height: 45px;
}
.cancel {
  margin-right: auto;
}

.cancel,
.cancel:hover {
  background: var(--red);
}
.confirm {
  margin-left: auto;
}

.correct,
.correct:hover {
  background: var(--yellow);
}

.confirm,
.confirm:hover {
  background: var(--green);
}

.qr {
  height: fit-content;
}

.html {
  background: #fff;
  padding: 5px 30px;
  border-radius: 5px;
  height: 100%;
  overflow: auto;
  color: #222;
}
