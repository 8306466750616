:root {
  --text: #202020;
  --receiptBg: #f5f4f4;
  --bottomMask: conic-gradient(from -45deg at bottom, #0000, #000 1deg 89deg, #0000 90deg) 50%/17px;
  --circleMask: radial-gradient(15px at 25px 40%, #0000 100%, #000) -25px;
}

.receipt {
  color: var(--text);
  padding: 25px 40px;
  line-height: 22px;
  white-space: break-spaces;
  position: relative;
  z-index: 0;
  margin: 10px auto;
  margin-bottom: 20px;
  width: fit-content;
  font-size: 16px;
}
.receiptBottom {
  position: absolute;
  width: 100%;
  height: 10px;
  bottom: -10px;
  left: 0;
}
.receiptBottom::before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  background: var(--receiptBg);
  mask: var(--bottomMask);
}

.receipt::before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  background: var(--receiptBg);
  mask: var(--circleMask);
}
