.content {
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-rows: 85% 1fr;
  gap: 15px;
}

.block {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.button {
  background: #093a7f;
  color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 10px 10px 0px var(--shadow);
  transition: 0.2s;
  min-height: 100px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.button:hover {
  background: #fff;
  border: none;
  color: #093a7f;
}

.listItem {
  border-radius: 3px;
  margin-bottom: 5px;
  background: #093a7f;
}
.listItem:hover {
  background: #062f68;
}
.listItemSelected {
  background: #fff !important;
  font-weight: bold;
  color: #093a7f;
}

.buttonsContainer {
  display: flex;
  width: 100%;
}
.actionButtons {
  padding: 10px 50px;
  white-space: nowrap;
  height: 45px;
}
.cancel {
  margin-right: auto;
}

.cancel,
.cancel:hover {
  background: var(--red);
}
.confirm {
  margin-left: auto;
}

.correct,
.correct:hover {
  background: var(--yellow);
}

.confirm,
.confirm:hover {
  background: var(--green);
}

.checkbox,
.checkbox.Mui-checked {
  color: #fff !important;
}
.checkboxLabel {
  margin: 0;
}
.static {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list {
  height: 150px;
  overflow: auto;
}

.controlContent {
  overflow: auto;
  height: 100%;

  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
}

.actionContent {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.multiInputsForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.base {
  text-align: center;
  background: #fff;
  border-radius: 5px;
  height: 100%;
  padding: 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.center {
  margin: auto 0;
  transform: translateY(-50%);
}

.subTitle {
  color: var(--secondary-colors-grey);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 5px;
}

.receivedText {
  color: #093a7f;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.label {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
