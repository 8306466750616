.tableWrapper {
  height: 100%;
  overflow: auto;
}

.table {
  background: #093a7f;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}
.cell {
  padding: 10px;
  width: 50%;
}

.cell:last-child {
  color: #093a7f;
  background: #fff;
}
