.atmWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  height: 500px;
  width: 700px;
  background-color: mediumseagreen;
  background: url(./assets/background.jpg);
  padding: 10px;
  background-size: cover;
  background-position: center center;
}
.atmTitle {
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding: 30px 30px 0;
}
.atmTitle p {
  font-size: 30px;
}

.atmWrapper button {
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  margin-top: auto;
  min-width: 200px;
  border: none !important;
  cursor: default;
}

.atmWrapper input {
  height: 60px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.buttonsContainer {
  margin-top: auto;
  display: flex;
  width: 100%;
}

.listItem {
  background-color: #fff !important;
  border: 2px solid green !important;
  border-radius: 0 !important;
  font-weight: bold !important;
  font-size: 16px !important;
  cursor: pointer;
}
.listItemSelected {
  background-color: green !important;
  color: #fff !important;
}

.langSelector {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

.checkboxLabel {
  font-size: 20px !important;
  color: #fff !important;
  font-weight: bold !important;
}

.receivedText {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.field {
  background: #fff;
}

.button {
  color: #202020;
  background-color: #fff !important;
}
