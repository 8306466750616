.qrCode {
  opacity: 0.7;
  transition: 0.2s;
  cursor: pointer;
}
.qrCode:hover {
  opacity: 1;
}

.qrCodePopover {
  padding: 10px;
  margin-top: 5px;
}
