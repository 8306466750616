:root {
  --backspace: #8b8b8b;
  --backspaceActive: #ff3838;
}

.input {
  border-radius: 5px;
  border: none;
  padding: 0 15px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
}

.inputWrap {
  display: flex;
  align-items: center;
  gap: 10px;
  height: fit-content;
}

.backspace {
  color: var(--backspace);
  cursor: pointer;
}
.backspace:active {
  color: var(--backspaceActive);
}
