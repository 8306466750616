:root {
  --text: #202020;
  --bg: #f4f4f5;
  --highlight: #ffa30c;
}

.table {
  background: var(--bg);
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-weight: bold;
}
.cell {
  padding: 5px 10px;
}

.cell:last-child {
  color: var(--highlight);
}
