:root {
  --text: #202020;
  --cardBg: #fff;
  --shadow: rgba(0, 0, 0, 0.1);
  --secondary-colors-grey: #8b8b8b;
  --red: #ff5454;
  --green: #08c799;
  --yellow: #ffa654;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 560px;
  width: 700px;
  color: var(--text);
}

.header {
  display: flex;
  min-height: 60px;
  padding: 0px 10px;
  align-items: center;
  background: #fff;
  width: 100%;
  justify-content: space-between;
}

.layout {
  height: 100%;
  max-height: 500px;
  width: 100%;
  padding: 40px;
  background: url(./assets/background.jpg);
  background-position: center;
  background-size: cover;
}

.logo {
  width: 80px;
  padding-left: 10px;
}

.qrAndLang {
  display: flex;
  gap: 30px;
  align-items: center;
}
