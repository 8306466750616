.wrapper {
  display: flex;
  gap: 20px;
}
.cards {
  display: grid;
  gap: 20px;
}

.group {
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 3px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
}

.card {
  min-width: 300px;
  background: #5db1b2;
  font-size: 35px;
  font-weight: bold;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: 0.2s;
}

.card:hover {
  box-shadow: 0px 0px 20px 2px #ddd;
  background: #4d9799;
}

.secondaryUrl {
  background: #67b25d;
}

.secondaryUrl:hover {
  background: #7c994d;
}
