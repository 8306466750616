:root {
  --text: #202020;
  --secondary-colors-grey: #8b8b8b;
}

.wrapper {
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  height: 100%;
}

.subTitle {
  color: var(--secondary-colors-grey);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 5px;
}

.receivedText {
  color: var(--text);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.block {
  margin-bottom: 15px;
}

.total {
  border-top: 2px solid #ddd;
  padding-top: 5px;
}

.buttons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.actionButtons {
  padding: 10px 20px;
  background: #222;
  text-transform: none;
}
.actionButtons:hover {
  background: #333;
}

.acceptApprove {
  min-width: 300px;
  text-align: center;
}
