.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 560px;
  width: 700px;
  background: linear-gradient(124deg, #093a7f -5%, #008ad2 100%);
}

.header {
  display: flex;
  min-height: 60px;
  padding: 0px 10px;
  align-items: center;
  background: #fff;
  width: 100%;
  justify-content: space-between;
}

.logo {
  width: 110px;
  padding-left: 10px;
}
.qrAndLang {
  display: flex;
  gap: 30px;
  align-items: center;
}

.layout {
  height: 100%;
  max-height: 500px;
  width: 100%;
  padding: 40px;
  background: url(./assets/background.svg);
  background-position: center;
  background-size: cover;
  color: #fff;
}
