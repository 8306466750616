.atmInterface {
  display: flex;
  position: relative;
}
.hardwareButtons {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.hardwareButtons button {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
