.content {
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-rows: 85% 1fr;
  gap: 15px;
}

.block {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}

.actionContent {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.multiInputsForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.button {
  background: #fff;
  color: var(--text);
  border: none;
  border-radius: 5px;
  box-shadow: 0px 10px 10px 0px var(--shadow);
  transition: 0.2s;
  min-height: 100px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button:hover {
  background: #fff;
  border: none;
}

.input {
  border-radius: 5px;
  border: none;
  padding: 0 15px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
}

.listItem {
  border-radius: 3px;
  margin-bottom: 5px;
  background: rgba(25, 118, 210, 0.08);
}
.listItemSelected {
  background: #fff !important;
  font-weight: bold;
}

.base {
  text-align: center;
  background: #fff;
  border-radius: 5px;
  height: 100%;
  padding: 20px;
  overflow: auto;
}

.subTitle {
  color: var(--secondary-colors-grey);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 5px;
}

.receivedText {
  color: var(--text);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.checkboxLabel {
  margin: 0;
}
.static {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.buttonsContainer {
  display: flex;
  width: 100%;
}

.actionButtons {
  padding: 10px 50px;
  white-space: nowrap;
  height: 45px;
}
.cancel {
  margin-right: auto;
}

.cancel,
.cancel:hover {
  background: var(--red);
}
.confirm {
  margin-left: auto;
}

.correct,
.correct:hover {
  background: var(--yellow);
}

.confirm,
.confirm:hover {
  background: var(--green);
}

.list {
  height: 150px;
  overflow: auto;
}

.controlContent {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
}

.label {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.infoLabel {
  text-align: center;
  font-weight: bold;
  display: grid;
  gap: 10px;
  grid-template-rows: 1fr max-content;
  overflow: auto;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
}
