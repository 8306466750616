:root {
  --border: #ffd301;
  --bg: #fff3c9;
  --bgActive: #f6e29b;
}

.nButton {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--border);
  background: var(--bg);
  position: relative;
  height: 35px;
  cursor: pointer;
}

.nButton:active {
  background: var(--bgActive);
}

.nButton span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.numpad {
  display: grid;
  width: 200px;
  gap: 5px;
  grid-template-columns: repeat(3, 1fr);
}

.numpadBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.numpad > *:nth-child(3n-2):nth-last-of-type(1) {
  grid-column: span 3;
}
