.inputWrap {
  display: flex;
  margin: 0 auto;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.steps {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}
