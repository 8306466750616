.loader {
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
}

.circle {
  color: #fff;
}
