* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

:root {
  --bg-color: rgba(0, 0, 0, 0.03);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  padding: 8px 20px;
}
input {
  padding: 8px;
}

.pageWrapper {
  height: 100vh;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qr {
  background: #fff;
  padding: 10px;
  width: fit-content;
}

.hardWareControls {
  padding: 20px;
  display: flex;
  column-gap: 20px;
  align-items: center;
}
