.nButton {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #043170;
  background: #093a7f;
  position: relative;
  height: 45px;
  cursor: pointer;
}

.nButton:active {
  background: #093a7f;
}

.nButton span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.numpad {
  display: grid;
  width: calc(100% - 40px);
  gap: 5px;
  grid-template-columns: repeat(3, 1fr);
}

.numpadBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: fit-content;
  margin: 0 auto;
}

.numpad > *:nth-child(3n-2):nth-last-of-type(1) {
  grid-column: span 3;
}
