.loader {
  width: 64px;
  height: 64px;
}
.circle {
  stroke-width: 4px;
  color: #202020;
}

.inline {
  display: inline-block;
  width: 100%;
  min-width: 16px;
  min-height: 16px;
  height: 100%;
}
