.pageTitle {
  margin-bottom: 20px;
}

.title {
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 10px;
}

.subTitle {
  opacity: 0.9;
}
