.block {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.content {
  background: #093a7f;
  border-radius: 5px;
  padding: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label {
  display: flex;
  gap: 10px;
}

.static {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.field {
  display: grid;
  align-items: center;
  grid-template-columns: 30% 1fr;
  column-gap: 10px;
  color: #fff;
}
.fieldTitle {
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  margin: 0;
}
.fieldValue {
  background: #052a5c;
  padding: 10px;
  width: 100%;
}
.total {
  color: #e1056d;
}
.buttonsContainer {
  margin-top: auto;
  display: flex;
  width: 100%;
  column-gap: 20px;
  margin-top: 20px;
}
.actionButtons {
  padding: 10px 60px;
  width: 33%;
  white-space: nowrap;
}

.cancel,
.cancel:hover {
  background: var(--red);
}
.confirm {
  margin-left: auto;
}

.confirm,
.confirm:hover {
  background: var(--green);
}
.loadMore,
.loadMore:hover {
  background: var(--yellow);
}

.attention {
  color: #e1056d;
}
