.settings-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  opacity: 0.5;
  transition: 0.2s;
}
.settings-icon:hover {
  opacity: 7;
}

.settings-popover {
  padding: 20px 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}
.MuiTypography-root.settings-title {
  margin: 0;
  line-height: inherit;
  padding-left: 4px;
  margin-bottom: 20px;
}
.settings-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.field {
  height: 40px;
  min-width: 350px;
}

.text-field .MuiInputBase-root {
  height: 40px;
}

.activeUrl {
  margin-top: 10px;
  margin-bottom: 2px;
  margin-left: 8px;
  color: #5c5c5c;
  font-size: 14px;
}

.apiSelecting {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 16px 8px 10px 8px;
  display: grid;
  gap: 16px;
}
